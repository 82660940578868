<div class="alphabet-scroll"
     [class.alphabet-scroll_double-state]="doubleState">
  <div class="alphabet-scroll__content">
    <ng-container *ngTemplateOutlet="template; context: {ctx: this}"></ng-container>
  </div>

  <div *ngIf="!hiddenAlphabet"
       class="alphabet-scroll__bar"
       #letterBar>
    <button *ngFor="let item of alphabetWithStates"
            class="alphabet-scroll__letter"
            [disabled]="item.disabled"
            [ngClass]="{
              active: item.active,
              disabled: item.disabled
            }"
            (click)="scrollToSection(item)"
            #letter>
      {{item.letter}}
    </button>
  </div>
</div>
