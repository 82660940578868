import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { ILegacySocietyMembers } from 'src/app/pages/legacy-society-members/typings';
import { animations } from '../../animations';
import { AnimationByScrollDirective } from '../../directives/animation-by-scroll.directive';

@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.scss'],
  animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersDetailComponent {
  @ViewChild(VirtualScrollerComponent)
  private virtualScroller: VirtualScrollerComponent;
  @ViewChildren(AnimationByScrollDirective) animationByScrollDirective;

  @Input() content: ILegacySocietyMembers[];
  @Input() activeItem: string;
  @Input() activePropName: string;
  @Input() hiddenAlphabet: boolean;

  scrollDuration: number;
  imageMinHeight = 280;

  private firstLoad = true;

  constructor(private sanitizer: DomSanitizer) {}

  videoUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  trackByFn(item: unknown): unknown {
    return item;
  }

  scrollToItemIndex(index: number): void {
    this.scrollDuration = this.firstLoad ? 0 : 500;
    setTimeout(() => this.virtualScroller.scrollToIndex(index));
    this.firstLoad = false;
  }

  changedItems(): void {
    this.animationByScrollDirective.forEach((el) =>
      setTimeout(() => el.checkElPosition(), this.scrollDuration)
    );
  }
}
