import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScrollService } from '../services/scroll.service';

@Directive({
  selector: '[animationByScroll]'
})
export class AnimationByScrollDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() heightVisibleElToShow = 250;

  private subscriptions = new Subscription()

  constructor(
    private el: ElementRef,
    private scrollService: ScrollService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.subscriptions
    .add(
      this.scrollService.mousewheel$.subscribe(() => this.checkElPosition())
    )
    .add(
      this.scrollService.scroll$.subscribe(() => this.checkElPosition())
    );
  }

  ngAfterViewInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'hide');
    setTimeout(() => this.checkElPosition());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  checkElPosition(): void {
    const el = this.el.nativeElement;
    const elTopPos = el.getBoundingClientRect().top;
    const valueToShow = window.innerHeight - this.heightVisibleElToShow;
    const valueToHide = window.innerHeight;
    const classToShow = 'show';

    if (elTopPos <= valueToShow) {
      this.renderer.addClass(el, classToShow);
    } else if (elTopPos > valueToHide) {
      this.renderer.removeClass(el, classToShow);
    }
  }
}
