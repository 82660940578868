import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnimationsService {
  private isAppLoaded: boolean;

  startLoadedAnimation(): void {
    if (this.isAppLoaded) {
      document.body.classList.add('page-loaded');
    } else {
      this.appLoaded();
    }
  }

  stopLoadedAnimation(): void {
    document.body.classList.remove('page-loaded');
  }

  private appLoaded(): void {
      document.body.classList.add('app-loaded');
      this.isAppLoaded = true;
  }
}
