import { NgModule } from '@angular/core';
import { APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { ScrollService } from './services/scroll.service';
import { DataService } from './services/data.service';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { AlphabetScrollComponent } from './components/alphabet-scroll/alphabet-scroll.component';
import { UsersDetailComponent } from './components/users-detail/users-detail.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { AnimationByScrollDirective } from './directives/animation-by-scroll.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';

export function playerFactory(): LottiePlayer { return player; }

const initializeConfig = (dataService: DataService) => {
  return () =>
    new Promise((resolve) => {
      dataService.refreshToken().subscribe().add(resolve);
    });
};

@NgModule({
  declarations: [
    AlphabetScrollComponent,
    UsersDetailComponent,
    SafeUrlPipe,
    PreloaderComponent,
    AnimationByScrollDirective,
    OnlyNumbersDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    VirtualScrollerModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  exports: [
    AlphabetScrollComponent,
    UsersDetailComponent,
    PreloaderComponent,
    VirtualScrollerModule,
    AnimationByScrollDirective,
    OnlyNumbersDirective
  ],
  providers: [
	ScrollService,
	DataService,
	{ provide: APP_INITIALIZER, useFactory: initializeConfig, deps: [DataService], multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ]
})
export class SharedModule { }
