import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Injectable()
export class ScrollService {
  scroll$: Observable<Event>;
  mousewheel$: Observable<Event>;

  constructor() {
    this.scroll$ = fromEvent(document, 'scroll');
    this.mousewheel$ = fromEvent(window, 'mousewheel');
  }
}
