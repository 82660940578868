import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { ERoutingPath } from 'src/app/routing-models';
import { animations } from 'src/app/shared/animations';
import { ESidebarStates, SidebarStateService } from './sidebar-state.service';
import { NavigationEnd, Router } from '@angular/router';

interface INavItem {
  title: string;
  displayText: string,
  icon: string;
  path: ERoutingPath;
  topPosition?: number;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild('navEl') nav: ElementRef<HTMLElement>;
  @ViewChild('navActiveLine') navActiveLine: ElementRef<HTMLElement>;

  navList: INavItem[] = [
    {
      title: 'home',
      displayText: 'Mission & About of TJF',
      icon: 'icon-menu-home',
      path: ERoutingPath.home
    },
    {
      title: 'history',
      displayText: '​History of TJF',
      icon: 'icon-menu-history',
      path: ERoutingPath.history
    },
    {
      title: 'affiliate-partners',
      displayText: 'Affiliate ​Partners',
      icon: 'icon-menu-partners',
      path: ERoutingPath.affiliatePartners
    },
    {
      title: 'giving-list',
      displayText: 'Giving List',
      icon: 'icon-menu-giving-list',
      path: ERoutingPath.givingList
    },
    {
      title: 'legacy-society-members',
      displayText: 'Legacy Society Members',
      icon: 'icon-menu-legacy-society-members',
      path: ERoutingPath.legacySocietyMembers
    },
    {
      title: 'life-legacy-program',
      displayText: 'Life & Legacy Program​',
      icon: 'icon-menu-lifelegacy-program',
      path: ERoutingPath.lifeLegacyProgram
    },
    {
      title: 'legacy-society-members-videos',
      displayText: 'Donor ​Videos​',
      icon: 'icon-menu-video',
      path: ERoutingPath.legacySocietyMembersVideos
    },
    {
      title: 'join-us',
      displayText: 'Join Our Book of Life​',
      icon: 'icon-menu-join',
      path: ERoutingPath.joinUs
    }
  ];
  state: ESidebarStates;
  linkBackPath: string;
  ready: boolean;
  ESidebarStates = ESidebarStates;
  isMobileNavOpened: boolean = false;
  routerSub = new Subscription();

  private subscriptions = new Subscription();

  constructor(private sidebarStateService: SidebarStateService, private cdr: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    this.setStateAndLinkBack();

    this.subscriptions.add(
      fromEvent(window, 'resize').subscribe(() => this.setActiveLinePosition())
    );

    this.routerSub = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isMobileNavOpened = false;
        document.body.classList.remove('overflow-hidden');
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.routerSub.unsubscribe();
  }

  private setStateAndLinkBack(): void {
    let isFirstAnimation = true;

    this.subscriptions.add(
      this.sidebarStateService.state.subscribe(state => {
        this.state = state;
        this.linkBackPath = this.sidebarStateService.linkBackPath;
        this.ready = true;
        this.cdr.detectChanges();

        setTimeout(() => this.setActiveLinePosition(), isFirstAnimation ? 300 : 0);
        isFirstAnimation = false;
      })
    );
  }

  private setActiveLinePosition(): void {
    if (this.navActiveLine) {
      const navTopPos = this.nav?.nativeElement.getBoundingClientRect().top;
      const navItemActive = document.querySelector('.nav__item_active');
      const navItemActiveTopPos = navItemActive?.getBoundingClientRect().top;

      this.navActiveLine.nativeElement.style.top = (navItemActiveTopPos - navTopPos) + 'px';
    }
  }


  toggleMobileNav(): void {
    this.isMobileNavOpened = !this.isMobileNavOpened;
    document.body.classList.toggle('overflow-hidden');
  }

}
