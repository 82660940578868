import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
  EventEmitter,
  Renderer2,
  OnDestroy,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface IAlphabetWithStates {
  letter: string;
  active?: boolean;
  disabled?: boolean;
}

const DATA_ATTR = 'data-letter';

@Component({
  selector: 'app-alphabet-scroll',
  templateUrl: './alphabet-scroll.component.html',
  styleUrls: ['./alphabet-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlphabetScrollComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @ViewChild('letterBar') letterBar: ElementRef<HTMLElement>;
  @ViewChildren('letter') letters: QueryList<ElementRef<HTMLElement>>;

  @Input() items: any[];
  @Input() filterProp: string;
  @Input() template: TemplateRef<unknown>;
  @Input() scrollYOffset = 0;
  @Input() enableScrollToSection = true;
  @Input() scrollElement: HTMLElement;
  @Input() activeItem: string;
  @Input() activePropName: string;
  @Input() doubleState: boolean;
  @Input() hiddenAlphabet: boolean;

  @Output() scrollToItemIndex = new EventEmitter<number>();

  alphabetWithStates: IAlphabetWithStates[];

  protected alphabet = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];

  private subscription = new Subscription();
  private letterSections: NodeListOf<HTMLElement>;
  private activeItemIndex: number;

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items?.currentValue) {
      this.createAlphabetWithStates();
      this.createItems();
    }
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      fromEvent(this.scrollElement || window, 'scroll')
        .pipe(debounceTime(80))
        .subscribe(() => {
          this.letterSections = document.querySelectorAll(`[${DATA_ATTR}]`);

          this.checkSectionPosition();
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  scrollToSection(item: IAlphabetWithStates): void {
    if (this.enableScrollToSection) {
      const scrollToSectionEl = document.querySelector(
        `[${DATA_ATTR}="${item.letter}"]`
      );
      const y =
        scrollToSectionEl.getBoundingClientRect().top +
        window.pageYOffset +
        Number(this.scrollYOffset);

      (this.scrollElement || window).scrollTo({ top: y, behavior: 'smooth' });
    }

    this.scrollToItemIndex.emit(this.getItemByLetter(item.letter));
  }

  private createAlphabetWithStates(): void {
    this.alphabetWithStates = [];

    this.alphabet.forEach((letter) => {
      this.alphabetWithStates.push({ letter, disabled: true });
    });
  }

  private createItems(): void {
    let firstActiveStatus: boolean;

    this.sortItems();
    this.items.forEach((item, index) => {
      item.letter = item[this.filterProp].substring(0, 1).toLowerCase();

      // turn off disabled letter
      this.alphabetWithStates.forEach((letterObj) => {
        if (letterObj.letter === item.letter) {
          letterObj.disabled = false;

          if (!firstActiveStatus) { letterObj.active = firstActiveStatus = true; }
        }
      });

      if (
        this.activeItem &&
        item[this.activePropName].toLowerCase() === this.activeItem &&
        this.activeItemIndex === undefined
      ) {
        this.activeItemIndex = index;
        this.scrollToItemIndex.emit(this.activeItemIndex);
      }
    });

    if (this.doubleState) { this.setFirstDoubleActiveLetter(); }
  }

  private setFirstDoubleActiveLetter(): void {
    const maxActiveCount = 2;
    let activeCount = 0;

    this.alphabetWithStates.forEach((letter) => {
      if (!letter.disabled && activeCount < maxActiveCount) {
        letter.active = true;
        ++activeCount;
      }
    });
  }

  private checkSectionPosition(): void {
    let doubleActiveLetters = [];

    this.letterSections.forEach((item) => {
      const letter = item.attributes[DATA_ATTR].value;
      const itemTopPos = item.getBoundingClientRect().top;
      const itemCenterPos =
        itemTopPos + item.getBoundingClientRect().height / 2;
      const centerWindow = window.innerHeight / 2;
      const itemInView =
        itemTopPos < centerWindow || itemCenterPos < centerWindow;

      if (itemInView && itemCenterPos >= 0) {
        if (this.doubleState) {
          doubleActiveLetters.length > 1
            ? (doubleActiveLetters = [letter])
            : doubleActiveLetters.push(letter);
        } else {
          this.scrollToActiveLetter(letter);
        }
      }
    });

    this.setActiveDoubleLetter(doubleActiveLetters);
  }

  private scrollToActiveLetter(activeLetter: string): void {
    this.letters.forEach((letter) => {
      const letterEl = letter.nativeElement;
      const compareLetter = letterEl.innerText.toLowerCase() === activeLetter;
      const letterBarEl = this.letterBar.nativeElement;

      if (compareLetter) {
        const y =
          letterEl.getBoundingClientRect().top +
          letterBarEl.scrollTop -
          window.innerHeight / 2;

        letterBarEl.scrollTo({ top: y, behavior: 'smooth' });
      }

      this.setActiveLetter(letterEl, compareLetter);
    });
  }

  private sortItems(): void {
    this.items.sort((a, b) => {
      const keyA = a[this.filterProp];
      const keyB = b[this.filterProp];

      if (keyA < keyB) { return -1; }
      if (keyA > keyB) { return 1; }

      return 0;
    });
  }

  private getItemByLetter(letter: string): number {
    return this.items.findIndex(
      (item) => item[this.filterProp].substring(0, 1).toLowerCase() === letter
    );
  }

  private setActiveLetter(letterEl: HTMLElement, compareLetter: boolean): void {
    if (!this.doubleState) { this.renderer.removeClass(letterEl, 'active'); }
    if (compareLetter) { this.renderer.addClass(letterEl, 'active'); }
  }

  private setActiveDoubleLetter(doubleActiveLetters: string[]): void {
    if (doubleActiveLetters.length > 1) {
      this.letters.forEach((letter) => {
        const letterEl = letter.nativeElement;

        this.renderer.removeClass(letterEl, 'active');

        doubleActiveLetters.forEach((activeLetter) => {
          const compareLetter =
            letterEl.innerText.toLowerCase() === activeLetter;

          if (compareLetter) { this.renderer.addClass(letterEl, 'active'); }
        });
      });

      this.scrollToActiveLetter(doubleActiveLetters[0]);
    }
  }
}
