import {
  trigger,
  transition,
  style,
  animate,
  state,
  group,
} from '@angular/animations';

export const animations = [
  trigger('fadeOutSlow', [
    transition(':enter', [
      style({
        opacity: 0,
      }),
      animate(
        '200ms 500ms',
        style({
          opacity: 1,
        })
      ),
    ]),
    transition(':leave', [
      style({
        opacity: 1,
      }),
      animate(
        '200ms 500ms',
        style({
          opacity: 0,
        })
      ),
    ]),
  ]),
  trigger('slideInOut', [
    state('in', style({ height: '*', opacity: 0 })),
    transition(':leave', [
      style({ height: '*', opacity: 1 }),

      group([
        animate(200, style({ height: 0 })),
        animate('200ms ease-in-out', style({ opacity: '0' })),
      ]),
    ]),
    transition(':enter', [
      style({ height: '0', opacity: 0 }),

      group([
        animate(200, style({ height: '*' })),
        animate('400ms ease-in-out', style({ opacity: '1' })),
      ]),
    ]),
  ]),
];
