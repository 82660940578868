import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ERoutingPath } from './routing-models';

const routes: Routes = [
  {
    path: ERoutingPath.home,
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: ERoutingPath.history,
    loadChildren: () =>
      import('./pages/history/history.module').then((m) => m.HistoryModule),
  },
  {
    path: ERoutingPath.affiliatePartners,
    loadChildren: () =>
      import('./pages/affiliate-partners/affiliate-partners.module').then(
        (m) => m.AffiliatePartnersModule
      ),
  },
  {
    path: ERoutingPath.givingList,
    loadChildren: () =>
      import('./pages/giving-list/giving-list.module').then(
        (m) => m.GivingListModule
      ),
  },
  {
    path: ERoutingPath.legacySocietyMembers,
    loadChildren: () =>
      import(
        './pages/legacy-society-members/legacy-society-members.module'
      ).then((m) => m.LegacySocietyMembersModule),
  },
  {
    path: ERoutingPath.lifeLegacyProgram,
    loadChildren: () =>
      import('./pages/life-legacy-program/life-legacy-program.module').then(
        (m) => m.LifeLegacyProgramModule
      ),
  },
  {
    path: ERoutingPath.legacySocietyMembersVideos,
    loadChildren: () =>
      import(
        './pages/videos-legacy-society-member/videos-legacy-society-member.module'
      ).then((m) => m.VideosLegacySocietyMemberModule),
  },
  {
    path: ERoutingPath.joinUs,
    loadChildren: () =>
      import('./pages/join-us/join-us.module').then((m) => m.JoinUsModule),
  },
  {
    path      : '**',
    redirectTo: ERoutingPath.home,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
