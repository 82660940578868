import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum ESidebarStates {
  normal = 'normal',
  topBackLink = 'top-back-link',
  bottomBackLink = 'bottom-back-link'
}

@Injectable({
  providedIn: 'root'
})
export class SidebarStateService {
  state = new Subject<ESidebarStates>();
  linkBackPath = '/';

  constructor() { }

  setState(state: ESidebarStates): void {
    this.state.next(state);
  }
}
