import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnimationsService } from '../../services/animations.service';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  host: { class: 'preloader-wrap' }
})
export class PreloaderComponent implements OnInit, OnDestroy {
  loaderAnimation = {
    path: './app/assets/animations/loader.json',
    name: 'loader'
  }

  constructor(private animationsService: AnimationsService) {}

  ngOnInit(): void {
    this.animationsService.stopLoadedAnimation();
  }

  ngOnDestroy(): void {
    this.animationsService.startLoadedAnimation();
  }
}
