import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';
import { IAffiliateParntner, IAffiliateParntnerResponse } from 'src/app/pages/affiliate-partners/typings';
import { IGivingList, IGivingListResponse } from 'src/app/pages/giving-list/typings';
import { IJoinUsFormData } from 'src/app/pages/join-us/typings';
import { ILegacySocietyMembers, ILegacySocietyMembersResponse } from 'src/app/pages/legacy-society-members/typings';
import { EUserCategories } from '../typings';
import { Tokens } from '../models/tokens';

@Injectable()
export class DataService {
  //protected baseURL = window.location.origin;
  protected baseURL = 'https://bol.eastus.cloudapp.azure.com/';

  constructor(private http: HttpClient) {}

  // GET
  getAffiliatePartners(): Observable<IAffiliateParntner[]> {
    return this.http.get(this.baseURL + '/wp/wp-json/sd/v1/partners').pipe(
      map((response: IAffiliateParntnerResponse[]) => {
        const partners = response.map(partner => {
          return {
            id: partner.id,
            title: partner.title,
            address: partner.adress,
            content: partner.descriptions,
            images: partner.images || [partner.img]
          };
        });

        return partners;
      })
    );
  }

  getGivingList(): Observable<IGivingList[]> {
    return this.http.get(this.baseURL + '/wp/wp-json/sd/v1/donations').pipe(
      map((response: IGivingListResponse[]) => {
        const givingList = response.map(givingItem => {
          return {
            id: givingItem.id,
            title: givingItem.title,
            content: givingItem.descriptions
          };
        });

        return givingList;
      })
    );
  }

  getLegasySocietyMembers(): Observable<ILegacySocietyMembers[]> {
    return this.http.get(this.baseURL + '/wp/wp-json/sd/v1/members').pipe(
      map((response: ILegacySocietyMembersResponse[]) => {
        const users = response.map(user => {
          return {
            id: user.id,
            name: user.name,
            surname: user.last_name,
            vip: user.vip,
            images: user.images || [user.img],
            society: user.society,
			slug: user.slug,
            info: user.descriptions,
            videos: user.videos || [user.video]
          };
        });

        return users;
      })
    );
  }

  getLegasySocietyMembersVIPs(): Observable<ILegacySocietyMembers[]> {
    return this.http.get(this.baseURL + '/wp/wp-json/sd/v1/members').pipe(
      map((response: ILegacySocietyMembersResponse[]) => {
        const users = response.map(user => {
          if (user.vip === EUserCategories.vip) {
            return {
              id: user.id,
              name: user.name,
              surname: user.last_name,
              vip: user.vip,
              images: user.images || [user.img],
              society: user.society,
			  slug: user.slug,
              info: user.descriptions,
              videos: user.videos || [user.video]
            };
          }
        }).filter(notUndefined => notUndefined !== undefined);

        return users;
      })
    );
  }

  getLegasySocietyMembersWithVideo(): Observable<ILegacySocietyMembers[]> {
    return this.http.get(this.baseURL + '/wp/wp-json/sd/v1/members').pipe(
      map((response: ILegacySocietyMembersResponse[]) => {
        const users = response.map(user => {
          if (user.vip === EUserCategories.vip && (user.video || user.videos)) {
            return {
              id: user.id,
              name: user.name,
              surname: user.last_name,
              vip: user.vip,
              images: user.images || [user.img],
              society: user.society,
			  slug: user.slug,
              info: user.descriptions,
              videos: user.videos || [user.video]
            };
          }
        }).filter(notUndefined => notUndefined !== undefined);

        return users;
      })
    );
  }

  getLifeLegacyProgramMember(id: string): Observable<ILegacySocietyMembers[]> {
    return this.http.get(this.baseURL + `/wp/wp-json/sd/v1/members/${id}`).pipe(
      map((response: ILegacySocietyMembersResponse) => {
        return [{
          id: response.id,
          name: response.name,
          surname: response.last_name,
          vip: response.vip,
          images: response.images || [response.img],
          society: response.society,
		  slug: response.slug,
          info: response.descriptions,
          videos: response.videos || [response.video]
        }];
      })
    );
  }

  // POST
  sendRequest(data: IJoinUsFormData): any {
    return this.http.post(this.baseURL + '/wp/wp-json/sd/v1/joins', data);
  }
  
  getToken() {
    return localStorage.getItem('access_token');
  }

  refreshToken() {
    return this.http.get<any>(this.baseURL + '/wp/wp-json/sd/v1/auth/refresh', {headers:{skip:"true"}}).pipe(tap((tokens: Tokens) => {
      this.storeJwtToken(tokens.jwt);
      return tokens.jwt;
    }));
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem('access_token', jwt);
  }
}
