<div *ngIf="ready"
     class="sidebar sidebar_{{state}}">
  <!-- top back link -->
  <div *ngIf="state === ESidebarStates.topBackLink"
       class="sidebar__top-wrap">
    <ng-container *ngTemplateOutlet="backBtn"></ng-container>
  </div>

  <!-- logo -->
  <div class="logo"
       [class.logo_hidden]="state === ESidebarStates.topBackLink"
       routerLink="/">
    <picture [hidden]="state === ESidebarStates.topBackLink">
      <source media="(max-width: 1279px)"
              srcset="/app/assets/images/logo.png">
      <source media="(max-width: 1580px)"
              srcset="/app/assets/images/logo-small.png">
      
      <img src="/app/assets/images/logo.png"
           alt="Tidewater Jewish Foundation​">
    </picture>
  </div>

  <!-- bottom back link -->
  <div *ngIf="state === ESidebarStates.bottomBackLink; else nav"
       class="sidebar__bottom-wrap">
    <ng-container *ngTemplateOutlet="backBtn"></ng-container>
  </div>
  <!-- nav -->
  <ng-template #nav>
    <button *ngIf="state != ESidebarStates.topBackLink" 
            class="nav-opener" 
            (click)="toggleMobileNav()">
      <span *ngIf="!isMobileNavOpened" class="nav-opener__open icon-menu"></span>
      <span *ngIf="isMobileNavOpened" class="nav-opener__close icon-menu-close"></span>
    </button>
    <nav class="nav"
         [ngClass]="{'nav_opened': isMobileNavOpened}"
         #navEl>
      <div *ngFor="let item of navList"
           class="nav__item {{item.title}}"
           [routerLink]="[item.path]"
           routerLinkActive="nav__item_active"
           [routerLinkActiveOptions]="{exact: true}">
        <button class="nav__btn {{item.icon}}"></button>
        <span class="nav__text">{{item.displayText}}</span>
      </div>
      <span class="nav__active-line"
            #navActiveLine></span>
    </nav>
  </ng-template>
</div>

<!-- back btn template -->
<ng-template #backBtn>
  <button class="sidebar__back-btn icon-arrow-back"
          [routerLink]="[linkBackPath]"></button>
</ng-template>
