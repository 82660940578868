export enum ERoutingPath {
  home = '',
  history = 'history',
  affiliatePartners = 'affiliate-partners',
  givingList = 'giving-list',
  legacySocietyMembers = 'legacy-society-members',
  lifeLegacyProgram = 'life-legacy-program',
  legacySocietyMembersVideos = 'legacy-society-members-videos',
  joinUs = 'join-us'
}
