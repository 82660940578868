<div *ngIf="content && content[0]; else loading"
     class="users-detail with-bg-block"
     #userDetail>
  <app-alphabet-scroll [items]="content"
                       [template]="customTemplate"
                       [enableScrollToSection]="false"
                       [scrollElement]="userDetail"
                       [activeItem]="activeItem"
                       [activePropName]="activePropName"
                       [hiddenAlphabet]="hiddenAlphabet"
                       filterProp="surname"
                       (scrollToItemIndex)="scrollToItemIndex($event)">
    <ng-template #customTemplate
                 let-ctx="ctx">
      <virtual-scroller [items]="ctx.items"
                        [parentScroll]="userDetail"
                        [enableUnequalChildrenSizes]="true"
                        [scrollAnimationTime]="scrollDuration"
                        [bufferAmount]="10"
                        class="users-detail__viewport"
                        (vsEnd)="changedItems()"
                        #scroll>

        <section *ngFor="let item of scroll.viewPortItems; trackBy: trackByFn"
                 animationByScroll
                 class="users-detail__item"
                 [attr.data-letter]="item.letter"
                 #userSection>

          <div class="section users-detail__section">
            <div class="users-detail__content section__content-left">
              <strong class="section__title">
                {{item.society}}
              </strong>
              <h2 class="title">{{item.name}} {{item.surname}}</h2>
              <div class="users-detail__text"
                   [innerHTML]="item.info"></div>
            </div>

            <div *ngIf="item.images[0]"
                 class="users-detail__images media-content section__content-right"
                 [ngStyle]="{'min-height': imageMinHeight * item.images.length+ 'px'}">
              <div *ngFor="let image of item.images; trackBy: trackByFn"
                   class="users-detail__image-wrap">
                <img [src]="image"
                     alt="Photo {{item.surname}}">
              </div>
            </div>
          </div>

          <div *ngIf="item.videos[0]"
               class="media-content users-detail__videos">
            <ng-container *ngFor="let videoSrc of item.videos; trackBy: trackByFn">
              <iframe [src]="videoSrc | safeUrl"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen></iframe>
            </ng-container>
          </div>
        </section>

      </virtual-scroller>
    </ng-template>
  </app-alphabet-scroll>
</div>

<ng-template #loading>
  <app-preloader [@fadeOutSlow]></app-preloader>
</ng-template>
